<template>
	<div>
		<div class="ft20 cl-main ftw600">预约工单</div>

		<div class="mt20">
			<div class="form-search-box ">
				<a-form layout="inline">
					<a-form-item label="预约ID">
						<a-input v-model="search.appointment_id" placeholder="请输入预约编号ID"></a-input>
					</a-form-item>

					<a-form-item label="客户">
						<a-input v-model="search.member" placeholder="请输入客户姓名/手机号"></a-input>
					</a-form-item>

					<a-form-item label="预约时间">
						<a-range-picker @change="changeAppointTime" v-model="search.appoint_time"  format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" />
					</a-form-item>


				</a-form>

				<a-form layout="inline">

					<a-form-item label="状态">
						<a-select v-model="search.status" style="width: 240px;">
							<a-select-option :value="0">全部状态</a-select-option>
							<a-select-option :value="1">待确认</a-select-option>
							<a-select-option :value="3">待服务</a-select-option>
							<a-select-option :value="4">服务中</a-select-option>
							<a-select-option :value="8">已完成</a-select-option>
							<a-select-option :value="-1">已取消</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="项目分类">
						<a-select v-model="search.project_type_id" style="width: 240px;">
							<a-select-option :value="0">全部分类</a-select-option>
							<a-select-option :value="item.project_type_id" v-for="(item,index) in type_list" :key="index">{{item.name}}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item>
						<a-button @click="searchAct" type="primary">查询</a-button>
						<a-button @click="cancelAct" class="ml10">取消</a-button>
					</a-form-item>
				</a-form>
			</div>
		</div>

		<div class="mt20">
			<div class="pd30 bg-w " style="min-height: 800px;">
				<div>
					<a-button type="primary" icon="plus" @click="addAppointmentAct()">新增预约</a-button>
				</div>
				<div class="mt20">
					<div class="wxb-table-gray">
						<a-table rowKey="appointment_id" :columns="columns" :pagination="pagination" @change="handleTableChange"
							:data-source="datas" :loading="loading">

							<div class="flex alcenter left" slot="day" slot-scope="day,record">
								<div style="line-height: 1.2;">
									<div>{{day}}</div>
									<div class="cl-theme">{{record.start_time}}-{{record.end_time}}</div>
								</div>
							</div>

							<div class="flex alcenter left" slot="member" slot-scope="member,record">
								<img :src="member.face"  class="member-face" />
								<div class="ml10" style="text-align: left;">
									<div>{{member.nick_name}}</div>
									<div>{{member.mobile}}</div>
								</div>
							</div>

							<div class="flex alcenter left" slot="project_id" slot-scope="project_id,record">
								<img :src="record.project_cover_img"  class="projetc-cover-img" />
								<div class="ml10 " style="text-align: left;">
									<div class="text-over4">{{record.project_name}}</div>
									<div class="flex alcenter">
										<div class="ft12 ftw400 cl-notice">规格：</div>
										<div class="ft12 ftw400 cl-notice text-over4">{{record.project_sku_name}}</div>
									</div>
									<div class="flex alcenter">
										<div class="ft12 ftw400 cl-notice">价格：</div>
										<div class="ft12 ftw400 cl-notice text-over4">{{record.total_price}}</div>
									</div>
								</div>
							</div>

							<div  slot="serviceman" slot-scope="serviceman,record">
								<div class="flex alcenter left" v-if="serviceman">
									<img :src="serviceman.face"  class="member-face" />
									<div class="ml10" style="text-align: left;">
										<div>{{serviceman.name}}</div>
										<div>{{serviceman.mobile}}</div>
									</div>
								</div>
								<div class="flex alcenter center" v-else>待分配</div>
							</div>

							<div class="flex alcenter center" slot="status" slot-scope="status,record">
								<div v-if="status==1 || status==4 || status==3">{{record.status_means}}</div>
								<div v-if="status==8 || status==-1" class="cl-notice">{{record.status_means}}</div>
							</div>

							<div class="flex alcenter center" slot="is_paid" slot-scope="is_paid,record">
								{{is_paid==1 ? '已支付'+record.need_pay : '未支付'}}
							</div>

							<template slot="action" slot-scope="record,index">
								<div class="flex center">
									<a-dropdown placement="bottomRight">
										<span class="more-act">
											<i class="iconfont iconmore_gray"></i>
										</span>
										<a-menu slot="overlay">
											<a-menu-item>
												<a class="menu-act" href="javascript:;" @click="showDetailAct(record)">
													<i class="iconfont ft14 iconsee"></i>
													<span class="ml10">查看详情</span>
												</a>
											</a-menu-item>
											<a-menu-item v-if="record.status==1">
												<a class="menu-act" href="javascript:;" @click="sureAppointAct(record)">
													<i class="iconfont ft14 iconorder_operation_confirm"></i>
													<span class="ml10">确认预约</span>
												</a>
											</a-menu-item>
											<a-menu-item v-if="record.status==3">
												<a class="menu-act" href="javascript:;" @click="startServiceAct(record)">
													<i class="iconfont ft14 iconorder_operation_startservice"></i>
													<span class="ml10">开始服务</span>
												</a>
											</a-menu-item>
											<a-menu-item v-if=" record.status==4">
												<a class="menu-act" href="javascript:;"  @click="completeAppointAct(record)">
													<i class="iconfont ft14 iconorder_operation_cancle"></i>
													<span class="ml10">完成服务</span>
												</a>
											</a-menu-item>
											<a-menu-item v-if="record.status==1 || record.status==3">
												<a class="menu-act" href="javascript:;"  @click="cancelAppointAct(record)">
													<i class="iconfont ft14 iconorder_operation_cancle"></i>
													<span class="ml10">取消预约</span>
												</a>
											</a-menu-item>

										</a-menu>
									</a-dropdown>
								</div>
							</template>
						</a-table>
					</div>
				</div>
			</div>
		</div>

		<div v-if="addAppointmentVisible">
			<add-appointment :visible="addAppointmentVisible" @cancel="cancelAddAppointment" @ok="okAddAppointment"></add-appointment>
		</div>
		<!-- <div v-if="showDetailVisible">
			<order-detail :visible="showDetailVisible" :order="order" @cancel="cancelShowDetail" @sureAppoint="sureAppoint"
			@cancelAppoint="cancelAppoint" @sureWriteOff="sureWriteOff"></order-detail>
		</div> -->
		<div v-if="cancelAppointVisible">
			<cancel-appoint :appointment_id="appointment_id" :visible="cancelAppointVisible" @cancel="cancelCancelAppoint" @ok="okCancelAppoint"></cancel-appoint>
		</div>
		<div v-if="distributionServicemanVisible">
			<distribution-serviceman :appointment_id="appointment_id" :visible="distributionServicemanVisible" @cancel="cancelDistributionServiceman"
			@ok="okDistributionServiceman"></distribution-serviceman>
		</div>

		<div v-if="completeAppointVisible">
			<complete-appoint :order="order" :visible="completeAppointVisible" @cancel="cancelCompleteAppoint" @ok="okCompleteAppoint"></complete-appoint>
		</div>
	</div>
</template>

<script>
	import {listMixin} from '@/common/mixin/list';
	import addAppointment from './components/order/modal/addAppointment.vue';
	// import orderDetail from './components/order/modal/detail.vue';
	import cancelAppoint from './components/order/modal/detail/modal/cancelAppoint.vue';
	import completeAppoint from './components/order/modal/completeAppoint.vue';
	import distributionServiceman from './components/order/modal/distributionServiceman.vue';
	export default {
		mixins: [listMixin],
		components:{
			addAppointment,
			// orderDetail,
			cancelAppoint,
			distributionServiceman,
			completeAppoint,
		},
		data() {
			return {
				loading: false,
				addAppointmentVisible:false,
				// showDetailVisible:false,
				cancelAppointVisible:false,
				distributionServicemanVisible:false,
				completeAppointVisible:false,
				order:{},
				appointment_id:0,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				search: {
					appointment_id:'',
					member:'',
					appoint_time:[],
					status: 0,
					project_type_id:0,
				},
				type_list:[],
				columns: [
					{title: '预约ID',dataIndex: 'appointment_id',width:100,align: 'center'},
					{title: '预约时间',dataIndex: 'day',align: 'left',width:120,scopedSlots: {customRender: 'day'}},
					{title: '用户',dataIndex: 'member',align: 'left',width:180,scopedSlots: {customRender: 'member'}},
					{title: '预约项目',dataIndex: 'project_id',align: 'left',width:200,scopedSlots: {customRender: 'project_id'}},
					{title: '预约人员',dataIndex: 'serviceman',align: 'left',width:180,scopedSlots: {customRender: 'serviceman'}},
					{title: '下单时间',dataIndex: 'add_time_format',align: 'center',ellipsis: true},
					{title: '支付状态',dataIndex: 'is_paid',align: 'center',scopedSlots: {customRender: 'is_paid'}},
					{title: '状态',dataIndex: 'status',align: 'center',scopedSlots: {customRender: 'status'}},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}}
				],
				datas: [],
			}
		},
		methods: {
			getLists() {
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('platform/getAppointmentList',{
					limit:this.pagination.pageSize,
					page:this.pagination.current,
					appointment_id:this.search.appointment_id,
					member:this.search.member,
					appoint_time:this.search.appoint_time,
					project_type_id:this.search.project_type_id,
					status:this.search.status,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					if(res.type_list){
						this.type_list=res.type_list;
					}
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},

			sureAppointAct(record){
				if(record.serviceman_id!=0){
					this.$confirm({
						title:'确认这个预约吗？',
						okText:"确定",
						okType:"danger",
						cancelText:"取消",
						onOk : ()=>{
							return new Promise((resolve,reject)=>{
								this.$http.api('platform/sureAppointment',{
									appointment_id:record.appointment_id,
								}).then(res=>{
									this.$message.success('确认成功');
									this.getLists();
								}).catch(res=>{
									console.log(res);
								}).finally(()=>{
									resolve();
								})
							})
						}
					})
				}else{
					this.distributionServicemanAct(record);
				}
			},

			distributionServicemanAct(record){
				this.appointment_id=record.appointment_id;
				this.distributionServicemanVisible=true;
			},
			cancelDistributionServiceman(){
				this.distributionServicemanVisible=false;
			},
			okDistributionServiceman(){
				this.distributionServicemanVisible=false;
				this.getLists();
			},

			startServiceAct(record){
				this.$confirm({
					title:'确认开始服务了吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('platform/startService',{
								appointment_id:record.appointment_id,
							}).then(res=>{
								this.$message.success('操作成功');
								this.getLists();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},

			//完成服务
			completeAppointAct(record){
				this.order=record,
				this.completeAppointVisible=true;
			},
			cancelCompleteAppoint(){
				this.completeAppointVisible=false;
			},
			okCompleteAppoint(){
				this.completeAppointVisible=false;
				this.getLists();
			},

			//新增预约
			addAppointmentAct(){
				this.addAppointmentVisible=true;
			},
			cancelAddAppointment(){
				this.addAppointmentVisible=false;
			},
			okAddAppointment(){
				this.addAppointmentVisible=false;
				this.getLists();
			},

			//查看订单详情
			showDetailAct(record){
				this.$router.push('/appointmentAdmin/orderDetail?id='+record.appointment_id)
			},
			// cancelShowDetail(){
			// 	this.showDetailVisible=false;
			// },
			// sureAppoint(){
			// 	this.showDetailVisible=false;
			// 	this.getLists();
			// },
			// cancelAppoint(){
			// 	this.showDetailVisible=false;
			// 	this.getLists();
			// },
			// sureWriteOff(){
			// 	this.showDetailVisible=false;
			// 	this.getLists();
			// },

			//取消预约
			cancelAppointAct(record){
				this.appointment_id=record.appointment_id;
				this.cancelAppointVisible=true;
			},
			cancelCancelAppoint(){
				this.cancelAppointVisible=false;
			},
			okCancelAppoint(){
				this.cancelAppointVisible=false;
				this.getLists();
			},


			changeAppointTime(value){
				this.search.appoint_time=value;
			},

			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
		}
	}
</script>

<style>
	.projetc-cover-img{
		width: 60px;
		height: 40px;
	}

	.member-face{
		width: 40px;
		height: 40px;
		border-radius: 20px;
	}
</style>
